import { css } from '@emotion/react'

export const contatosEVisitas = css`
  background: #efefef;
  padding: 60px 0px;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  scroll-margin-top: 117px;
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: #33535E;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #33535E;
    input[type="datetime-local"]::-webkit-calendar-picker-indicator{
      filter: invert(0.3);
    }
  }
  form {
    p {
      font-family:"obviously-narrow";
    font-variation-settings: "wght" 700;
    }
    input {
      width: 100%;
      height: 52px;
      background: transparent;
      border-bottom: 2px solid #33535E;
      border-top: none;
      border-left: none;
      border-right: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #33535E;
      padding-left: 0px;
      padding-right: 24px;
      outline: none;
      &::placeholder {
        color: #33535E;
      }
    }
    select {
      border: 1px solid #33535E;
      width: 100%;
      height: 52px;
      background: #33535E;
      padding-left: 24px;
      padding-right: 24px;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .MB-120 {
      margin-bottom: 120px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .MB-120 {
      margin-bottom: 120px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    .MB-120 {
      margin-bottom: 120px;
    }
  }
`

export const btnEnviar = css`
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.8px;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid #33535E;
  width: 130px;
  height: 52px;
  padding: 12px 24px 14px 32px;
  display: flex;
  justify-content: center;
  color: #33535E;
  transition: transform 2000ms ease,opacity 2000ms ease,color 500ms ease,background 500ms ease,-webkit-transform 2000ms ease;
  margin-top: 40px;
  :hover {
    background: #C2F57E;
    border: #C2F57E;
    color: #312F2D;
    text-decoration: none;
  }
  :active {
    border: 2px solid #C2F57E;
    color: #C2F57E;
    background: transparent;
  }
`