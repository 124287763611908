/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import { Link } from "gatsby"
import ImgDiferencial from "@/images/lp/atividades-adicionais.png"
import ImgDiferencialMob from "@/images/lp/atividades-adicionais-mob.png"
import ImgTeatroBert from "@/images/lp/teatro-bert.png"
import ImgFabLab from "@/images/lp/fablab.png"
import ImgStrike from "@/images/lp/equipe-strike.png"
import { 
  atividadesAdicionais,
  DDesktop,
  DMobile,
  diferenciaisContent,
  mb88,
}  from '../assets/styles/ExcelenciaInternacional.styles'

const AtividadesAdicionais = () => {
  return (
    <Fragment>
      <section css={atividadesAdicionais}>
        <div className="text-right">
          <img style={{ marginLeft: 'auto' }} css={DDesktop} src={ImgDiferencial} alt='/' />
          <img css={DMobile} src={ImgDiferencialMob} alt='/' />
        </div>
        <Container>
          <Row>
            <Col lg={1} md={1} sm={12} />
            <Col lg={11} md={11} sm={12}>
              <h2>atividades adicionais</h2>
            </Col>
          </Row>
          <Row style={{ marginTop: 100 }}>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <h3>Ampliando horizontes e potenciais através de atividades exclusivas: descubra as oportunidades incríveis oferecidas pelo Bert International Schools!</h3>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <p>Na Bert International Schools, buscamos oferecer aos nossos alunos atividades complementares que vão além do currículo tradicional. Através dessas atividades, incentivamos o desenvolvimento de habilidades únicas, como empreendedorismo, colaboração, autoconfiança, tecnologia e criatividade. Nossos alunos têm a oportunidade de explorar seus talentos e ampliar seus horizontes, preparando-se para um mundo globalizado e cheio de desafios. Conheça três de nossas atividades especiais abaixo:</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={diferenciaisContent}>
        <Container>
          <Row id='CIA-teatro' css={mb88}>
            <Col lg={1} md={1} sm={12} />
            <Col lg={6} md={5} sm={12}>
              <img src={ImgTeatroBert} alt='/' />
            </Col>
            <Col lg={5} md={5} sm={12}>
              <h3>companhia de teatro bert</h3>
              <p>A CIA de Teatro Bertoni é uma oportunidade única para os alunos explorarem a arte do teatro enquanto desenvolvem habilidades de liderança, autogestão e expressão criativa. Através de ensaios, produções teatrais e apresentações, os alunos aprimoram sua confiança, habilidades de comunicação e trabalho em equipe, além de vivenciarem clássicos do teatro. No Bert International School, o palco é um espaço de aprendizado e transformação.</p>
              {/* <div>
                <Link to='/essencial-para-os-pais'>ACESSE E SAIBA MAIS</Link>
              </div> */}
            </Col>
          </Row>
          <Row id='FABLAB' css={mb88}>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <img css={DMobile} src={ImgFabLab} alt='/' />
              <h3>colaborative fablab</h3>
              <p>No Bert, contamos com um laboratório de fabricação digital inspirado no renomado MIT - Instituto de Tecnologia de Massachusetts. O Digital FabLab é um espaço onde os alunos podem desenvolver projetos e enfrentar os desafios da nova economia digital. Integrando conhecimentos de tecnologia, engenharia, artes e design, os alunos exploram sua criatividade, resolução de problemas e pensamento inovador, preparando-se para as demandas do futuro.</p>
              {/* <div>
                <Link to='/essencial-para-os-pais'>ACESSE E SAIBA MAIS</Link>
              </div> */}
            </Col>
            <Col lg={6} md={5} sm={12}>
              <img css={DDesktop} src={ImgFabLab} alt='/' />
            </Col>
          </Row>
          <Row id='STRIKE'>
            <Col lg={1} md={1} sm={12} />
            <Col lg={6} md={5} sm={12}>
              <img src={ImgStrike} alt='/' />
            </Col>
            <Col lg={5} md={5} sm={12}>
              <h3>Strike - Equipe de Robótica</h3>
              <p>A Strike 6902 é a equipe de robótica formada por alunos do Bert International Schools, que já conquistou duas vezes o título de campeã mundial. Além de mergulharem no mundo da tecnologia, os alunos aprendem lições valiosas de design, marketing e gestão, desenvolvendo habilidades multifacetadas. Com intercâmbios culturais nas viagens internacionais, a Strike promove uma experiência enriquecedora, ampliando horizontes e promovendo a colaboração global.</p>
              {/* <div>
                <Link to='/essencial-para-os-pais'>ACESSE E SAIBA MAIS</Link>
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default AtividadesAdicionais;
