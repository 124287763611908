import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import AtividadesAdicionaisContent from "@/components/AtividadesAdicionais";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import ContatosBgWhite from '../components/Contatos-bg-white';

const AtividadesAdicionais = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="O essencial é diferencial.">
          <HeaderOne />
          <AtividadesAdicionaisContent />
          <ContatosBgWhite />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default AtividadesAdicionais;
